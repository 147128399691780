<template>
    <v-container fluid v-if="!$auth.isAuthenticated && !$auth.loading">
        <v-row style="height: 100vh" class="align-center">
            <v-col xs="12" md="6">
                <div class="d-flex flex-wrap justify-center align-center mt-2 black--text" style="height: 100%">
                    <div style="width: 350px">
                        <v-img class="mb-14" contain src="@/assets/Nuvolos-transparent.svg" />
                        <seamless-access-button :redirect="this.$route?.query?.targetUrl ? this.$route.query.targetUrl : '/'" class="mb-4" />
                        <google-oauth-btn :redirect="this.$route?.query?.targetUrl ? this.$route.query.targetUrl : '/'"></google-oauth-btn>
                        <v-alert class="mt-4" type="error" v-if="this.$route.query.error">{{ this.$route.query.error_description }}</v-alert>
                        <div class="d-flex mt-8 align-center">
                            <v-divider />
                            <span class="caption px-2">OR</span>
                            <v-divider />
                        </div>
                        <v-form v-model="valid" @submit="checkLoginType(email)" onSubmit="return false;" class="mt-7 w-100">
                            <v-text-field
                                placeholder="Nuvolos Account Email"
                                class="font-weight-light"
                                id="nv-email"
                                v-model="email"
                                :rules="[rules.nonEmptyUsername]"
                                style="font-size: 13px"
                                outlined
                                dense
                                e2e-login-input></v-text-field>
                            <v-alert text type="error" v-if="checkLoginTypeError" class="mt-4">
                                <div class="d-flex flex-column">
                                    <span class="font-weight-bold">Error</span>
                                    We encountered an error. Please check the system status or reach out to us using the button below.
                                    <v-btn light outlined @click="showIntercomChat" class="text-decoration-underline" color="red">Help</v-btn>
                                </div>
                            </v-alert>
                            <v-alert
                                text
                                type="error"
                                class="mt-4"
                                v-if="$auth.error && $auth.error.message && $auth.error.message.toLowerCase().includes('expiration')">
                                There was a login error! If you continue to see this message, please check if your system time is correctly set.
                            </v-alert>
                            <v-btn type="submit" outlined block :disabled="!valid" :loading="checkingLoginMethod" class="mt-n2 text-none" e2e-signin-email>
                                Sign-in with e-mail
                            </v-btn>
                        </v-form>
                        <div class="mt-8">
                            <div class="mb-2 text-center">
                                <a style="text-decoration: none; font-size: 13px" @click="getEmailHelp" class="black--text">
                                    Don't remember your sign-up e-mail?
                                </a>
                            </div>
                            <div class="text-center">
                                <a style="text-decoration: none; font-size: 13px" class="black--text" @click="getAccountHelp">Don't have an account?</a>
                            </div>
                            <div v-if="showEmailHelp" class="mt-2">
                                <v-alert type="error" v-if="failedEmail">No account found for {{ failedEmail }}.</v-alert>
                                <v-alert type="info" class="my-4">
                                    Please check the invitation e-mail you received from Nuvolos and use the e-mail address it was sent to. You might also be
                                    able to log in directly using the "Access through your institution" button above.
                                </v-alert>
                                <div class="caption mt-2">
                                    If you have trouble accessing your account, you can
                                    <a @click="showIntercomChat">ask for help</a>
                                    .
                                </div>
                            </div>
                            <div v-if="showAccountHelp" class="mt-2">
                                <v-alert type="info">
                                    Students accessing Nuvolos require an invitation from their course administrator to create an account.
                                </v-alert>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="6">
                <main-hero class="mr-md-15" />
            </v-col>
        </v-row>
        <v-dialog max-width="500" :value="showWAYFChange" persistent>
            <v-card>
                <v-card-title>
                    <v-icon color="info" class="mr-1">info</v-icon>
                    Updated Institution Selector
                </v-card-title>
                <v-card-text>
                    <p>We have updated the institution selector:</p>
                    <v-img src="@/assets/wayfinder-change.png" class="my-2" />
                    <p>
                        Please search for the
                        <b>full institution name (with accents)</b>
                        on the next screen. This needs to be done only once.
                    </p>
                    <p>Thank you for your understanding!</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="SATOSALogin()" text>Continue</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
    <full-screen-loader v-else />
</template>

<script>
import { defaultAccountType } from '../utils'
import store from 'store'
import { intercomMethods } from '@/mixins/intercom'
import MainHero from '@/components/MainHero'
const SeamlessAccessButton = () => import('@/components/SeamlessAccessButton.vue')
const GoogleOauthBtn = () => import('@/components/GoogleOauthBtn.vue')
const FullScreenLoader = () => import('@/views/TheFullScreenLoader')

export default {
    components: { FullScreenLoader, SeamlessAccessButton, MainHero, GoogleOauthBtn },
    mixins: [intercomMethods],
    data() {
        return {
            canShowError: true,
            email: store.get('nvLastEmail'),
            password: '',
            showpassword: false,
            showSwitchWarning: false,
            switchWarning: 'Based on your email address, please consider logging in with your SWITCH account using the above button.',
            error: '',
            checkLoginTypeError: false,
            rules: {
                nonEmptyPassword: p => p.length > 0 || 'Enter password',
                nonEmptyUsername: v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            },
            valid: false,
            lastEmail: store.get('nvLoginEmail'),
            checkingLoginMethod: false,
            loggingIn: false,
            showEmailHelp: false,
            showAccountHelp: false,
            failedEmail: '',
            showWAYFChange: false
        }
    },
    mounted() {
        const script = document.createElement('script')
        script.setAttribute('src', 'https://nuvolos.statuspage.io/embed/script.js')
        document.head.appendChild(script)
    },
    methods: {
        checkLoginType(email) {
            store.set('nvLastEmail', email)
            this.checkingLoginMethod = true
            this.$axios
                .post('/users/login_methods', { email })
                .then(response => {
                    this.checkLoginTypeError = false
                    if (response.data && response.data.methods && response.data.methods.length) {
                        if (response.data.methods[0].toLowerCase() === 'switch') {
                            this.SATOSALogin()
                        } else if (response.data.methods[0].toLowerCase() === 'google') {
                            this.googleLogin()
                        } else {
                            this.$auth.loginWithRedirect({
                                connection: process.env.VUE_APP_AUTH0_CONNECTION,
                                login_hint: email,
                                appState: { targetUrl: this.$route.query && this.$route.query.targetUrl ? this.$route.query.targetUrl : '/' }
                            })
                        }
                    } else {
                        this.showEmailHelp = true
                        this.failedEmail = email
                    }
                })
                .catch(e => {
                    this.checkLoginTypeError = true
                    this.failedEmail = ''
                })
                .finally(() => {
                    this.checkingLoginMethod = false
                })
        },
        getEmailHelp() {
            this.showEmailHelp = true
            this.showAccountHelp = false
        },
        getAccountHelp() {
            this.showEmailHelp = false
            this.showAccountHelp = true
        },
        openForgotPassword() {
            window.open('https://id.alphacruncher.net/midpoint/forgotpassword', 'Reset Password', 'width=500,height=500')
            this.$store.dispatch('showSnackBar', { snackBarText: 'You can close the new popup window after the password reset.', snackBarTimeout: 20000 })
        },
        SATOSALogin() {
            this.$auth.loginWithRedirect({
                connection: process.env.VUE_APP_AUTH0_SATOSA_CONNECTION,
                appState: { targetUrl: this.$route?.query?.targetUrl ? this.$route.query.targetUrl : '/' }
            })
        },
        googleLogin() {
            this.$auth.loginWithRedirect({
                connection: 'google-oauth2',
                appState: { targetUrl: this.$route?.query?.targetUrl ? this.$route.query.targetUrl : '/' }
            })
        }
    },
    watch: {
        email: function (nextVal, preVal) {
            if (defaultAccountType(preVal) === 'SWITCH' && defaultAccountType(nextVal) !== 'SWITCH' && this.showSwitchWarning) {
                this.showSwitchWarning = false
            }
        },
        '$auth.isAuthenticated': {
            handler(isAuthenticated) {
                if (isAuthenticated) {
                    if (this.$route.query && this.$route.query.targetUrl) {
                        this.$router.push({ path: this.$route.query.targetUrl })
                    } else {
                        this.$router.push({ name: 'welcome' })
                    }
                } else {
                    if (this.$route?.query?.logoutTarget) {
                        this.$router.push({ path: this.$route.query.logoutTarget })
                    }
                }
            },
            immediate: true
        }
    }
}
</script>

<style scoped lang="scss">
.emailSignIn {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
}

.emailSignIn span {
    background: #fff;
    padding: 0 5px;
    color: grey;
    font-weight: bold;
}
</style>
